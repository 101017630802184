import * as React from "react";

function SvgComponent() {
  return (
    <svg width={55.459} height={50.42} viewBox="0 -12 512.00001 512" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m40.390625 223.839844h42.023437c10.542969 0 19.09375-8.546875 19.09375-19.09375v-18.714844c0-10.546875-8.550781-19.09375-19.09375-19.09375h-42.023437c-10.546875 0-19.09375 8.546875-19.09375 19.09375v18.714844c0 10.546875 8.546875 19.09375 19.09375 19.09375zm0 0"
        fill="#8e8391"
      />
      <path
        d="m77.011719 223.84375h-36.617188c-10.546875 0-19.09375-8.546875-19.09375-19.09375v-18.714844c0-10.546875 8.546875-19.09375 19.09375-19.09375h36.617188c-10.546875 0-19.09375 8.546875-19.09375 19.09375v18.714844c0 10.546875 8.546875 19.09375 19.09375 19.09375zm0 0"
        fill="#766d78"
      />
      <path
        d="m464.328125 0c-26.171875 0-47.464844 21.289062-47.464844 47.457031 0 8.0625 2.03125 15.796875 6.039063 22.996094l74.011718 143.53125c1.371094 2.65625 4.089844 4.25 6.972657 4.25.613281 0 1.238281-.070313 1.855469-.21875 3.515624-.855469 5.996093-4.007813 5.996093-7.628906v-162.980469c-.003906-26.140625-21.269531-47.40625-47.410156-47.40625zm31.714844 178.050781-59.253907-114.90625c-.042968-.082031-.085937-.160156-.128906-.242187-2.722656-4.859375-4.101562-10.054688-4.101562-15.445313 0-15.277343 12.144531-31.765625 31.769531-31.765625 17.488281 0 31.714844 14.226563 31.714844 31.714844zm0 0"
        fill="#c8bebc"
      />
      <path
        d="m512 187.40625v79.53125 205.679688c0 8.683593-7.042969 15.714843-15.714844 15.714843h-480.570312c-8.671875 0-15.714844-7.042969-15.714844-15.714843v-250.96875c0-8.683594 7.042969-15.714844 15.714844-15.714844h100.027344c9.070312 0 16.4375-7.355469 16.4375-16.4375v-10.15625c0-12.628906 10.230468-22.859375 22.859374-22.859375h325.992188c17.097656 0 30.949219 13.839843 30.96875 30.925781zm0 0"
        fill="#fff5f4"
      />
      <path d="m0 266.9375h512v155.34375h-512zm0 0" fill="#fed22b" />
      <path
        d="m432.816406 330.195312c0 69.800782-56.582031 126.382813-126.378906 126.382813s-126.378906-56.582031-126.378906-126.382813c0-69.796874 56.582031-126.378906 126.378906-126.378906s126.378906 56.582032 126.378906 126.378906zm0 0"
        fill="#8e8391"
      />
      <path
        d="m398.519531 330.195312c0 50.855469-41.226562 92.082032-92.082031 92.082032s-92.082031-41.226563-92.082031-92.082032c0-50.855468 41.226562-92.082031 92.082031-92.082031s92.082031 41.226563 92.082031 92.082031zm0 0"
        fill="#655e67"
      />
      <path
        d="m338.648438 330.195312c0 17.792969-14.421876 32.210938-32.210938 32.210938s-32.210938-14.417969-32.210938-32.210938c0-17.789062 14.421876-32.210937 32.210938-32.210937s32.210938 14.421875 32.210938 32.210937zm0 0"
        fill="#444145"
      />
      <path
        d="m36.617188 221.648438v250.96875c0 8.671874 7.042968 15.714843 15.714843 15.714843h-36.617187c-8.671875 0-15.714844-7.042969-15.714844-15.714843v-250.96875c0-8.683594 7.042969-15.714844 15.714844-15.714844h36.617187c-8.671875 0-15.714843 7.03125-15.714843 15.714844zm0 0"
        fill="#ebe0da"
      />
      <path
        d="m191.65625 156.480469c-12.625 0-22.859375 10.230469-22.859375 22.859375v10.15625c0 9.082031-7.363281 16.4375-16.4375 16.4375h-36.617187c9.070312 0 16.4375-7.355469 16.4375-16.4375v-10.15625c0-12.628906 10.230468-22.859375 22.859374-22.859375zm0 0"
        fill="#ebe0da"
      />
      <path
        d="m0 266.9375h121.832031v155.34375h-121.832031zm0 0"
        fill="#fdc202"
      />
      <path
        d="m0 266.9375h36.617188v155.34375h-36.617188zm0 0"
        fill="#f0a70a"
      />
      <path
        d="m0 266.9375h36.617188v155.34375h-36.617188zm0 0"
        fill="#f0a70a"
      />
      <path
        d="m199.90625 208.128906c0 10.960938-8.886719 19.847656-19.847656 19.847656-10.960938 0-19.84375-8.886718-19.84375-19.847656 0-10.960937 8.882812-19.84375 19.84375-19.84375 10.960937 0 19.847656 8.882813 19.847656 19.84375zm0 0"
        fill="#fed22b"
      />
      <path
        d="m421.492188 219.351562h45.65625c8.578124 0 15.53125-6.953124 15.53125-15.535156 0-8.578125-6.953126-15.53125-15.53125-15.53125h-45.65625c-8.578126 0-15.535157 6.953125-15.535157 15.53125s6.957031 15.535156 15.535157 15.535156zm0 0"
        fill="#fed22b"
      />
      <path
        d="m324.746094 420.460938c-5.910156 1.195312-12.039063 1.820312-18.308594 1.820312-50.855469 0-92.078125-41.230469-92.078125-92.085938 0-50.859374 41.222656-92.078124 92.078125-92.078124 6.269531 0 12.398438.625 18.308594 1.820312-42.078125 8.484375-73.769532 45.667969-73.769532 90.257812 0 44.589844 31.691407 81.769532 73.769532 90.265626zm0 0"
        fill="#444145"
      />
    </svg>
  );
}

export default SvgComponent;
