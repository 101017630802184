import * as React from "react";

function SvgComponent() {
  return (
    <svg
    width={55.459} height={50.42}
      id="Capa_1"
      enable-background="new 0 0 512 512"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path
            d="m467.5 45v422c-16.57 0-30 13.43-30 30h-260c0-16.57-13.43-30-30-30v-422c16.57 0 30-13.43 30-30h260c0 16.57 13.43 30 30 30z"
            fill="#ffe278"
          />
        </g>
        <path
          d="m467.5 45v422c-16.57 0-30 13.43-30 30h-128.62v-482h128.62c0 16.57 13.43 30 30 30z"
          fill="#ffb454"
        />
        <g>
          <path
            d="m467.5 30c-8.27 0-15-6.73-15-15 0-8.28-6.72-15-15-15h-260c-8.28 0-15 6.72-15 15 0 8.27-6.73 15-15 15-8.28 0-15 6.72-15 15v422c0 8.28 6.72 15 15 15 8.27 0 15 6.73 15 15 0 8.28 6.72 15 15 15h260c8.28 0 15-6.72 15-15 0-8.27 6.73-15 15-15 8.28 0 15-6.72 15-15v-422c0-8.28-6.72-15-15-15zm-15 424.57c-12.77 4.53-22.9 14.66-27.43 27.43h-235.14c-4.53-12.77-14.66-22.9-27.43-27.43v-397.14c12.77-4.53 22.9-14.66 27.43-27.43h235.14c4.53 12.77 14.66 22.9 27.43 27.43z"
            fill="#ffb454"
          />
        </g>
        <g>
          <path
            d="m482.5 45v422c0 8.28-6.72 15-15 15-8.27 0-15 6.73-15 15 0 8.28-6.72 15-15 15h-128.62v-30h116.19c4.53-12.77 14.66-22.9 27.43-27.43v-397.14c-12.77-4.53-22.9-14.66-27.43-27.43h-116.19v-30h128.62c8.28 0 15 6.72 15 15 0 8.27 6.73 15 15 15 8.28 0 15 6.72 15 15z"
            fill="#ff8659"
          />
        </g>
        <g>
          <path
            d="m188.5 305h-112c-8.28 0-15 6.72-15 15v64c0 8.28 6.72 15 15 15h112c8.28 0 15-6.72 15-15v-64c0-8.28-6.72-15-15-15z"
            fill="#0a789b"
          />
        </g>
        <g>
          <path
            d="m203.5 320v64c0 8.28-6.72 15-15 15h-56v-94h56c8.28 0 15 6.72 15 15z"
            fill="#0a617d"
          />
        </g>
        <g>
          <path
            d="m220.5 369h-176c-8.28 0-15 6.72-15 15v113c0 8.28 6.72 15 15 15h176c8.28 0 15-6.72 15-15v-113c0-8.28-6.72-15-15-15z"
            fill="#0a617d"
          />
        </g>
        <g>
          <path
            d="m235.5 384v113c0 8.28-6.72 15-15 15h-88v-143h88c8.28 0 15 6.72 15 15z"
            fill="#08475e"
          />
        </g>
        <g>
          <path
            d="m386.35 261.42-7.22-34.9-6.72-32.46-24.22-117.1c-1.44-6.96-7.58-11.96-14.69-11.96-8.57 0-16.87 1.49-24.62 4.26-23.16 8.25-41.42 27.81-47.32 52.59-.17.7-.32 1.41-.47 2.12l-6.83 33.01-21.61 104.44c-7.19 34.73 3.49 70.44 28.57 95.52l37.67 37.67c2.77 2.77 6.36 4.23 9.99 4.37.21.02.41.02.62.02 3.84 0 7.68-1.46 10.61-4.39l37.67-37.67c25.08-25.08 35.76-60.79 28.57-95.52z"
            fill="#eb6173"
          />
        </g>
        <g>
          <path
            d="m357.78 356.94-37.67 37.67c-2.93 2.93-6.77 4.39-10.61 4.39-.21 0-.41 0-.62-.02v-329.72c7.75-2.77 16.05-4.26 24.62-4.26 7.11 0 13.25 5 14.69 11.96l24.22 117.1 6.72 32.46 7.22 34.9c7.19 34.73-3.49 70.44-28.57 95.52z"
            fill="#ff1f3d"
          />
        </g>
        <g>
          <path
            d="m324.5 296v144c0 8.28-6.72 15-15 15-.21 0-.42 0-.62-.02-7.99-.32-14.38-6.91-14.38-14.98v-144c0-8.07 6.39-14.66 14.38-14.98.2-.02.41-.02.62-.02 8.28 0 15 6.72 15 15z"
            fill="#e9e9ff"
          />
        </g>
        <g>
          <path
            d="m324.5 296v144c0 8.28-6.72 15-15 15-.21 0-.42 0-.62-.02v-173.96c.2-.02.41-.02.62-.02 8.28 0 15 6.72 15 15z"
            fill="#d8d8fc"
          />
        </g>
        <g>
          <path
            d="m299.11 180.61c-2.93 2.93-6.77 4.39-10.61 4.39s-7.68-1.46-10.61-4.39l-23.63-23.63 6.83-33.01c.15-.71.3-1.42.47-2.12l37.55 37.54c5.85 5.86 5.85 15.36 0 21.22z"
            fill="#ff1f3d"
          />
        </g>
        <g>
          <path
            d="m379.13 226.52-36.63 27.48c-2.7 2.02-5.86 3-8.99 3-4.56 0-9.06-2.07-12.01-6-4.97-6.63-3.63-16.03 3-21l47.91-35.94z"
            fill="#c30047"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
