const details = [
    {
      
      link: "https://udaan-ggv.github.io/Udaan_Magazine/external/pdfjs-2.1.266-dist/web/viewerSeason7.html ",

    },
    {

      link: "https://udaan-ggv.github.io/Udaan_Magazine/external/pdfjs-2.1.266-dist/web/viewerSeason8.html ",
      
    },
  ];
  
  export default details;