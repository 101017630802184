import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width={49.581} height={49.58} viewBox="-31 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path d="m90 90h90v150h-90zm0 0" fill="#ececf1" />
      <path
        d="m439.628906 44.429688-81.09375-39.023438c-3.617187-1.746094-7.734375-1.992188-11.46875-.632812-3.75 1.304687-6.828125 4.058593-8.558594 7.648437l-99.136718 206.074219 126.914062 13.007812 21.835938-45.394531-1.09375-16.042969 14.101562-10.996094 13.003906-27.03125-1.089843-16.039062 14.097656-11.003906 19.503906-40.542969c3.589844-7.472656.453125-16.433594-7.015625-20.023437zm0 0"
        fill="#ffe477"
      />
      <path
        d="m373.066406 78.984375c-7.414062-3.589844-16.40625-.4375-20.023437 7.015625-3.589844 7.472656-.457031 16.4375 7.015625 20.023438l54.074218 26.015624 13.007813-27.042968zm0 0"
        fill="#ff9100"
      />
      <path
        d="m327.027344 140.066406c-3.589844 7.472656-.457032 16.4375 7.015625 20.027344l54.078125 26.015625 13.007812-27.042969-54.078125-26.015625c-7.398437-3.574219-16.390625-.421875-20.023437 7.015625zm0 0"
        fill="#ff9100"
      />
      <path
        d="m135 150c-33.089844 0-60-26.910156-60-60 0-29.121094 40.679688-75.9375 48.835938-85.019531 5.6875-6.328125 16.644531-6.328125 22.324218 0 8.160156 9.082031 48.839844 55.898437 48.839844 85.019531 0 33.089844-26.910156 60-60 60zm0 0"
        fill="#ff728b"
      />
      <path d="m300 120v180h-90v-180l30-30h30zm0 0" fill="#75efff" />
      <path d="m300 120v180h-45v-210h15zm0 0" fill="#00ceee" />
      <path
        d="m300 120v-15c0-1.199219-.300781-3.601562-.902344-4.800781l-30-90c-1.800781-6-7.5-10.199219-14.097656-10.199219s-12.300781 4.199219-14.097656 10.199219l-30 90c-.601563 1.199219-.902344 3.601562-.902344 4.800781v15zm0 0"
        fill="#ffbcab"
      />
      <path
        d="m300 120v-15c0-1.199219-.300781-3.601562-.902344-4.800781l-30-90c-1.800781-6-7.5-10.199219-14.097656-10.199219v120zm0 0"
        fill="#ff9468"
      />
      <path
        d="m447 222.597656c-1.203125-7.199218-7.5-12.597656-14.703125-12.597656h-354.597656c-7.199219 0-13.5 5.398438-14.699219 12.597656-1.800781 10.203125-2.699219 19.203125-3 27.902344l-57.597656 86.097656c-2.402344 3.601563-3.003906 8.101563-1.800782 12.300782.898438 4.199218 3.898438 7.5 7.800782 9.601562l51.597656 25.800781v52.699219c0 24.898438 20.097656 45 45 45h75v15c0 8.398438 6.597656 15 15 15h182.398438c7.800781 0 14.398437-5.699219 15-13.5 5.402343-53.101562 19.5-110.601562 43.800781-171.5 12.898437-32.101562 16.5-68.398438 10.800781-104.402344zm0 0"
        fill="#ffcebf"
      />
      <path
        d="m436.199219 327c-24.300781 60.898438-38.398438 118.398438-43.800781 171.5-.601563 7.800781-7.199219 13.5-15 13.5h-122.398438v-302h177.296875c7.203125 0 13.5 5.398438 14.703125 12.597656 5.699219 36.003906 2.097656 72.300782-10.800781 104.402344zm0 0"
        fill="#ffb99c"
      />
    </svg>
  );
}

export default SvgComponent;
