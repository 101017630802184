import * as React from "react";

function SvgComponent(props) {
  return (
    <svg  width={55.459} height={50.42} viewBox="0 -32 512 512" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m464.867188 88.367188c-25.988282 0-47.132813 21.144531-47.132813 47.132812v224.933594c0 2.328125.539063 4.625 1.582031 6.707031l32.132813 64.269531c2.539062 5.082032 7.734375 8.289063 13.417969 8.289063 5.679687 0 10.875-3.207031 13.414062-8.289063l32.132812-64.269531c1.042969-2.082031 1.585938-4.378906 1.585938-6.707031v-224.933594c0-25.988281-21.144531-47.132812-47.132812-47.132812zm0 0"
        fill="#ff7579"
      />
      <path
        d="m288.132812 273.132812h-273.132812c-8.285156 0-15-6.714843-15-15v-193.867187c0-35.492187 28.773438-64.265625 64.265625-64.265625h238.867187v258.132812c0 8.285157-6.714843 15-15 15zm0 0"
        fill="#ffa300"
      />
      <path
        d="m352.933594 447.734375h-273.667969c-8.28125 0-15-6.714844-15-15v-322.398437h303.667969v322.398437c0 8.285156-6.714844 15-15 15zm0 0"
        fill="#eff6fe"
      />
      <path
        d="m360.433594 0h-296.167969c30.46875 0 55.167969 24.699219 55.167969 55.167969s-24.699219 55.167969-55.167969 55.167969h296.167969c30.46875 0 55.167968-24.699219 55.167968-55.167969s-24.699218-55.167969-55.167968-55.167969zm0 0"
        fill="#ffcc4b"
      />
      <path
        d="m415.601562 55.167969c0-30.46875-24.699218-55.167969-55.167968-55.167969h-148.082032c30.464844 0 55.164063 24.699219 55.164063 55.167969s-24.699219 55.167969-55.164063 55.167969h148.082032c30.46875 0 55.167968-24.699219 55.167968-55.167969zm0 0"
        fill="#ffb826"
      />
      <path
        d="m367.929688 110.335938v322.402343c0 8.277344-6.710938 15-15 15h-140.578126v-337.402343zm0 0"
        fill="#d9e4ef"
      />
      <g fill="#3ab4fb">
        <path d="m143.800781 197.636719h144.597657c8.285156 0 15-6.714844 15-15 0-8.285157-6.714844-15-15-15h-144.597657c-8.285156 0-15 6.714843-15 15 0 8.285156 6.714844 15 15 15zm0 0" />
        <path d="m143.800781 261.902344h144.597657c8.285156 0 15-6.714844 15-15s-6.714844-15-15-15h-144.597657c-8.285156 0-15 6.714844-15 15s6.714844 15 15 15zm0 0" />
        <path d="m143.800781 326.167969h144.597657c8.285156 0 15-6.714844 15-15 0-8.28125-6.714844-15-15-15h-144.597657c-8.285156 0-15 6.71875-15 15 0 8.285156 6.714844 15 15 15zm0 0" />
        <path d="m143.800781 390.4375h144.597657c8.285156 0 15-6.71875 15-15 0-8.285156-6.714844-15-15-15h-144.597657c-8.285156 0-15 6.714844-15 15 0 8.28125 6.714844 15 15 15zm0 0" />
      </g>
      <path
        d="m303.398438 182.632812c0 8.289063-6.71875 15-15 15h-76.046876v-30h76.046876c8.28125 0 15 6.71875 15 15zm0 0"
        fill="#1d9cfd"
      />
      <path
        d="m303.398438 246.902344c0 8.28125-6.71875 15-15 15h-76.046876v-30h76.046876c8.28125 0 15 6.71875 15 15zm0 0"
        fill="#1d9cfd"
      />
      <path
        d="m303.398438 311.171875c0 8.28125-6.71875 15-15 15h-76.046876v-30h76.046876c8.28125 0 15 6.710937 15 15zm0 0"
        fill="#1d9cfd"
      />
      <path
        d="m303.398438 375.433594c0 8.289062-6.71875 15-15 15h-76.046876v-30h76.046876c8.28125 0 15 6.71875 15 15zm0 0"
        fill="#1d9cfd"
      />
      <path
        d="m464.871094 88.367188v351.328124c5.679687 0 10.867187-3.207031 13.410156-8.289062l32.140625-64.269531c1.039063-2.078125 1.578125-4.371094 1.578125-6.699219v-224.941406c0-25.988282-21.140625-47.128906-47.128906-47.128906zm0 0"
        fill="#fe4a60"
      />
      <path
        d="m464.867188 88.367188c-25.988282 0-47.132813 21.144531-47.132813 47.132812v32.136719h94.265625v-32.136719c0-25.988281-21.144531-47.132812-47.132812-47.132812zm0 0"
        fill="#ffcc4b"
      />
      <path
        d="m512 135.496094v32.140625h-47.128906v-79.269531c25.988281 0 47.128906 21.140624 47.128906 47.128906zm0 0"
        fill="#ffb826"
      />
    </svg>
  );
}

export default SvgComponent;
